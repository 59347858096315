import React from "react"

// Tell webpack this JS file uses this image
import AspectAccompliImg from "../../content/assets/aspect-accompli.png"
import AspectIteratifImg from "../../content/assets/aspect-iteratif.png"
import AspectPonctuelImg from "../../content/assets/aspect-ponctuel.png"
import AspectProgressifImg from "../../content/assets/aspect-progressif.png"

export default () => (
    // The import result is the URL of your image
    <>
        <img src={AspectAccompliImg} alt="Aspect Accompli" />
        <img src={AspectIteratifImg} alt="Aspect Itératif" />
        <img src={AspectPonctuelImg} alt="Aspect Pontuel" />
        <img src={AspectProgressifImg} alt="Aspect Progressif" />
    </>
)
